<template>
  <div class="z-50 flex flex-row items-center justify-center gap-1.5">
    <!-- User count -->

    <client-only>
      <service-message-button
        v-if="user && user.id && useCheckPermissions(['admin | view'])"
      />

      <default-printers-button v-if="user && user.id" :variant="null" />
      <country-selector v-if="user && user.id" />
      <fr-button v-if="users" icon="people">
        <q-tooltip> {{ users }} users online </q-tooltip>
        <q-badge v-if="users" align="middle" class="max-h-8" floating>
          {{ users }}
        </q-badge>
      </fr-button>

      <chatbot-modal />

      <dark-mode-component />

      <fr-button
        v-if="user && user.id"
        class="h-12 w-12 items-center !rounded-full !p-0"
      >
        <fr-image
          class="h-full w-full rounded-full"
          :src="
            user?.profilePicture ||
            wrikeUser?.avatar_url ||
            'https://www.gravatar.com/avatar/de3c32db0ca79f1e0f4bfcf42a1d6451.jpg?s=80&d=mm&r=g'
          "
        />
        <q-menu
          class="m-0 min-w-[250px] p-0 shadow-none"
          transition-show="jump-down"
          transition-duration="400"
          :offset="[0, 5]"
        >
          <fr-card>
            <fr-card-header>
              <template #left>
                <div>
                  <h4 class="text-lg">{{ user?.name }}</h4>
                  <span class="text-sm font-light">{{ user?.email }}</span>
                </div>
              </template>

              <template #right>
                <fr-button
                  variant="ghost"
                  icon="account_circle"
                  @click="() => $router.push('/auth/account')"
                >
                  <q-tooltip> {{ $t('account.my_account') }} </q-tooltip>
                </fr-button>
              </template>
            </fr-card-header>

            <fr-card-body class="!flex-row justify-between">
              <regions-dropdown />
              <lang-picker />
            </fr-card-body>

            <q-separator />

            <div class="flex flex-row flex-nowrap justify-between p-2">
              <fr-button
                v-close-popup
                variant="ghost"
                icon="refresh"
                @click="() => useForceReset(true)"
              >
                <q-tooltip>{{ $t('account.force_reset') }}</q-tooltip>
              </fr-button>

              <fr-button
                v-close-popup
                variant="ghost"
                icon="logout"
                @click="
                  () =>
                    $q
                      .dialog({
                        title: $t('logout.confirm'),
                        message: $t('logout.logout_confirmation'),
                        ok: $t('logout.ok'),
                        cancel: $t('logout.cancel')
                      })
                      .onOk(() =>
                        refetchLogout().then(() => useForceReset(false, false))
                      )
                "
              >
                Logout
              </fr-button>
            </div>
          </fr-card>
        </q-menu>
      </fr-button>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import RegionsDropdown from '~/components/widgets/regions-dropdown-widget.vue'
import { useServiceDeskStore } from '~/features/service-desk/store'
import darkModeComponent from './theme-components/theme-switch-button.vue'
import ChatbotModal from '~/features/chatbot/components/modals/chatbot-modal.vue'
import { io } from 'socket.io-client'
import factories from '~/factories'
import CountrySelector from '~/features/country/components/country-selector-widget.vue'
import defaultPrintersButton from '~/features/printer/components/default-printers-button.vue'
import serviceMessageButton from '~/features/admin/components/service-messages/service-message-button.vue'

const ServiceDeskStore = useServiceDeskStore()
const $q = useQuasar()

const users = ref()

const user = useUser()

const socket = io(process.env.FR_PERMISSIONS_URL as string, {})

socket.on('users', (arg) => {
  users.value = arg
})

const wrikeUser = computed({
  get: () => ServiceDeskStore.wrikeUser,
  set: (value) => (ServiceDeskStore.wrikeUser = value)
})

const { refetch: refetchLogout } = factories.auth.queries.useLogout({
  enabled: false
})
</script>
